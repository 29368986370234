<template>
    <van-pull-refresh v-model="isLoading" @refresh="loadDoodStateLoad" style="overflow:visible">
    <div class="out" >
        
      <div class="header">
        <div class="logo Title">
          
          <div class="Chinese"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.purshMe')}}</div>
        </div>
        <div class="right-icon">
          <img src="../assets/img/logo-icon.png" alt="" />
        </div>
      </div>
      <div class="goods_info">
        
            <img :src="goods.pic+'?width=200'" alt="">
        
        <div>
            <div>{{$t('main.trname')}}：{{goods.name}}</div>
            <div>{{$t('main.trcode')}}{{goods.serial_number}}</div>
        </div>
      </div>
      <div class="show_talk">

        <div  v-for="(item, index) in talk_list" :key="item.id" :class="user_id == item.user_id ? 'self_talk':'oth_talk'">
            <div class="avatar">
                <img :src="item.user.avatar" alt="">
            </div>
            <div class="date_content">
                <div class="date">{{item.user_id == user_id?$t('main.mymessage'):item.user.username}} {{item.updated_at}}</div>
                <div class="content">
                    {{item.content}}
                </div>
            </div>
        </div>

      </div>
      <div class="show_end" ref="out" ></div>

      <div class="input_content">
        <input type="text" v-model="content" maxlength="150">
        <button @click="postContent">{{$t('main.send')}}</button>
    </div>

    </div>
</van-pull-refresh>
    
</template>


<script>
export default {
    data(){
        return{
            talk_list:[],
            content:'',
            isLoading:false,
            user_id:0,
            page:1,
            goods:{
                name:'',
                serial_number:'',
                pic:''
            },
            hasMore:false,
            avatar:null,
        }
    },
    mounted(){
        this.axios.get("/goods/info" + "?goods_id="+this.$route.query.goods_id ).then((res)=>{
            if(res.data.code != 0){
                weui.alert($t('main.trhbban'),{buttons:[{label:this.$t('main.confIrm')}]})
            }
            if(this.$i18n.locale == 'en'){
                res.data.data.name = res.data.data.name_intl ? res.data.data.name_intl : res.data.data.name
            }
            this.goods.name = res.data.data.name
            this.goods.serial_number = res.data.data.serial_number
            this.goods.pic = res.data.data.pictures[0]
            
        })
        this.axios.post('collection/getApplyBuyMessage',{apply_id:parseInt(this.$route.query.apply_id),page:this.page,type:parseInt(this.$route.query.type)}).then((res)=>{
            this.user_id = res.data.data.user_id
            this.talk_list = res.data.data.items
            this.hasMore = res.data.data.hasMorePages
            let avatar = this.talk_list.find((item)=>{
                return item.user_id == this.user_id
            }) || ''
            let no_show = res.data.data.noshow || 0
            if(no_show == 1){
                weui.alert(this.$t('main.trhbtran'),{buttons:[{label:this.$t('main.confIrm')}]})
            }
            if(avatar){
                this.avatar = avatar.avatar
            }
            this.$nextTick(()=>{
                this.$refs.out.scrollIntoView()
            })
        })
    },
    methods:{
        postContent(){
            if(this.content && !this.isLoading){
                this.isLoading = true
                this.axios.post('collection/addApplyBuyMessage',{apply_id:parseInt(this.$route.query.apply_id),content:this.content}).then((res)=>{
                    if(this.avatar){
                        this.talk_list.unshift({
                            user_id:this.user_id,
                            content:this.content,
                            updated_at:this.updated_get(),
                            user:{
                                avatar: this.avatar
                            }
                        })
                        this.content = ''
                        
                        this.isLoading = false
                        
                        this.$nextTick(()=>{
                            this.$refs.out.scrollIntoView()
                        })
                    }else{
                        this.axios.post('collection/getApplyBuyMessage',{apply_id:parseInt(this.$route.query.apply_id),page:this.page,type:parseInt(this.$route.query.type)}).then((res)=>{
                            
                            this.talk_list = res.data.data.items
                            
                            let avatar = this.talk_list.find((item)=>{
                                return item.user_id == this.user_id
                            }) || ''
                            if(avatar){
                                this.avatar = avatar.avatar
                            }
                            this.content = ''
                        
                            this.isLoading = false
                            this.$nextTick(()=>{
                                this.$refs.out.scrollIntoView()
                            })
                        })
                    }
                    
                    
                }).catch((err)=>{
                    this.isLoading = false
                })
            }
        },
        loadDoodStateLoad(){
            if(this.hasMore){
                this.page++
            }else if( this.page != 1){
                this.isLoading = false
                return
            }
            this.axios.post('collection/getApplyBuyMessage',{apply_id:parseInt(this.$route.query.apply_id),page:this.page,type:parseInt(this.$route.query.type)}).then((res)=>{
                if(this.hasMore){
                    let temp = this.talk_list.concat(res.data.data.items)
                    
                    this.talk_list = this.newArrFn(temp)
                }else{
                    this.talk_list = res.data.data.items
                }
            
            
            this.isLoading = false
            this.hasMore = res.data.data.hasMorePages
            })
        },
        add0(m){
            if(m >= 10){
                return m
            }
            return '0'+m
            
        },
        newArrFn(arr){
            for (let i = 0; i < arr.length; i++) {
                for (let j = i + 1; j < arr.length; j++) {
                    if (arr[i].id == arr[j].id && arr[i].content == arr[j].content && arr[i].updated_at == arr[j].updated_at ) {
                        arr.splice(j, 1);
                        j--;
                    };
                };
            };
            return arr;
        },
        updated_get(){
            let time =new Date()
            let year = time.getFullYear()
            let month = time.getMonth()
            let day = time.getDate()
            let hour = time.getHours()
            let min = time.getMinutes()
            let sec = time.getSeconds() 
            return year +'-' +this.add0(month) +'-'+ this.add0(day) +' '+ this.add0(hour)+':'+this.add0(min)+':'+this.add0(sec)
        }
    }
}
</script>

<style scoped>
:deep(.van-pull-refresh){
    overflow: visible!important;
}

.out {
  
  min-height: 100vh;
  /* overflow: auto; */
  box-sizing: border-box;
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

.header {
  display: flex;
  justify-content: space-between;
  /* line-height: 0; */
  margin-bottom: 0;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-top: 0.01rem;
  margin-bottom: 0.15rem;
  padding: 0.05rem 0.15rem;
  align-items: center;
  background: #fff;
}
.Title {
  font-size: 0.18rem;
  position: relative;
}

.English {
  color: #1b6a21;
  opacity: 0.3;
  position: relative;
  top: 0.18rem;
  left: 0.15rem;
  text-align: left;
}

.Chinese {
  color: #1b6a21;

  left: 0;
  position: relative;
  text-align: left;
}

.right-icon {
  width: 1rem;
  height: 0.39rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.input_content{
    position: fixed;
    bottom:0;
    background: #EEEEEE;
    width: 100%;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input_content input{
    border: none;
    border-radius: 0.04rem;
    background-color: #fff;
    width: 2.84rem;
    height: 0.37rem;
    border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
    font-size: 0.15rem;
    margin-right: 0.1rem;
}
.input_content button{
    border: none;
    border-radius: 0.04rem;
    background-color: #082952;
    color: #fff;
    font-size: 0.15rem;
    width: 0.56rem;
    height: 0.37rem;
}
.show_talk{
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    padding:0 0.15rem;
}
.show_end{
    height: 0.65rem;
}
.oth_talk{
    margin-top: 0.1rem;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.oth_talk .date_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.self_talk{
    
    margin-top: 0.1rem;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    
}
.self_talk .date_content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.oth_talk .avatar , .self_talk .avatar {
    width: 0.36rem;
    height: 0.36rem;
    margin-top: 0.16rem;
}
.oth_talk .avatar{
    margin-right: 0.1rem;
}
.self_talk .avatar{
    margin-left: 0.1rem;
}
.avatar img {
    width: 0.36rem;
    height: 0.36rem;
    border-radius: 0.08rem;
}
.oth_talk .date, .self_talk .date{
    font-size: 0.12rem;
    color: #999999;
}
.oth_talk .content{
    position: relative;
    max-width: 3.17rem;
    width: max-content;
    background: #fff;
    border-radius: 0.05rem;
    box-sizing: border-box;
    padding: 0.04rem 0.1rem;
    font-size: 0.16rem;
    color: #4D4948;
}
.oth_talk .content::before {
        position: absolute;
        top: 32%;
        left: -0.08rem;
        content: '';
        border: 0.04rem solid transparent;
        border-right-color: #fff;
}
.self_talk .content{
    position: relative;
    max-width: 3.17rem;
    width: max-content;
    background: #4D4948;
    border-radius: 0.05rem;
    box-sizing: border-box;
    padding: 0.04rem 0.1rem;
    font-size: 0.16rem;
    color: #fff;
}
.self_talk .content::after {
        position: absolute;
        top: 32%;
        right: -0.08rem;
        content: '';
        border: 0.04rem solid transparent;
        border-left-color: #4D4948;
}
.goods_info{
    margin: 0 auto;
    width: max-content;
    padding:  0.1rem;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 0.04rem;
    box-shadow: 0 0.02rem 12px 0 rgba(0, 0, 0, 0.3) ;
    position: sticky;
    top: 0.21rem;
    z-index: 3;
}
.goods_info img{
    width: 0.38rem;
    height: 0.38rem;
    margin-right: 0.1rem;
}
.goods_info > div > div {
    width: 2.2rem;
    height: 0.2rem;
    font-size: .12rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>